import style from './signInButton.module.scss'

export default function SignInButton({ buttonClicked, icon, title }) {
  return (
    <button
      onClick={buttonClicked}
      className={style.signInButton + ' button-black iconButton'}
    >
      <div>
        <div
          className='icon'
          dangerouslySetInnerHTML={{ __html: icon.svg }}
        ></div>
        <p>{title}</p>
      </div>
    </button>
  )
}
