import React, { Component } from 'react'
import { Redirect } from 'react-router-dom'

import firebase from '../lib/firebase'

import styles from './chat.module.scss'

import classNames from '../lib/classNames'

export default class Chat extends Component {
  constructor() {
    super()
    this.state = { loading: true }
  }

  componentDidUpdate(prevProps) {
    if (this.props.roomName !== prevProps.roomName) {
      let ref = this.state.messagesRef
      ref?.off()
      let id = this.getIdFromProps()
      if (id) this.addListener(id)
    }
  }

  addListener(roomId) {
    const messagesRef = firebase.database().ref('messages/' + roomId)
    this.setState({ messagesRef })
    messagesRef.on('value', snapshot => {
      const messages = snapshot.val() || []
      console.log(messages)
      this.setState(() => ({ loading: false, messages }))
    })
  }

  getIdFromProps() {
    let { rooms = [], roomName } = this.props
    return rooms.find(room => room.name === roomName)?.id
  }

  componentDidMount() {
    let id = this.getIdFromProps()
    if (id) this.addListener(id)
  }

  componentWillUnmount() {
    let ref = this.state.messagesRef
    ref.off()
  }

  render() {
    let { className, roomName, rooms = [] } = this.props

    if (roomName !== 'general' && !rooms.some(room => room.name === roomName)) {
      return <Redirect exact to='/room/general' />
    }

    return (
      <div className={classNames(className, styles.container)}>
        <h3>Room: #{roomName} </h3>
        <div className='messages'>
          {this.state.messages?.map(message => (
            <p key={message.senderName + message.date}>{message.content} from {message.senderName} at {message.date}</p>
          ))}
        </div>
      </div>
    )
  }
}
