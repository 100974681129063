import style from './sidebar.module.scss'
import RoomNavigationButton from './roomNavigationButton'

import Image from './image'

import classNames from '../lib/classNames'

export default function Sidebar({ user, logoutFunc, className, rooms = [] }) {
  console.log('ROOMS: ', rooms)
  let { userID, userName, userImage } = user
  return (
    <div className={classNames(style.container, className)}>
      {/* <div className={style.iconContainer}>
        <h2>Slack Clone</h2>
      </div> */}
      <div className={style.userSection}>
        <Image src={userImage} fallbackSrc='/user-solid.svg' alt='profil' />
        <div className={style.vertical}>
          <h3 className={style.userName}>{userName} </h3>
          <button onClick={logoutFunc} className='button-white button-small'>
            Logout
          </button>
        </div>
      </div>

      <hr />
      <div className={style.roomsSection}>
        <h5>rooms</h5>
        {rooms.map(room => (
          <RoomNavigationButton
            key={room.id}
            roomId={room.id}
            roomName={room.name}
            selected={false}
          />
        ))}
      </div>
    </div>
  )
}
