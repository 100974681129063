import style from './roomNavigationButton.module.scss'
import { Link } from 'react-router-dom'

import classNames from '../lib/classNames'

export default function RoomNavigationButton({
  roomName,
  roomId,
  selected = false,
}) {
  return (
    <div
      className={classNames(style.container, selected ? style.selected : '')}
    >
      <Link to={'/room/' + roomName}>
        <p>#{roomName}</p>
      </Link>
    </div>
  )
}
