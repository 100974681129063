// https://cdn.dribbble.com/users/275767/screenshots/5919285/aa.jpg

import React, { Component } from 'react'
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Redirect,
} from 'react-router-dom'

import firebase from './lib/firebase'

import Login from './components/login'
import Sidebar from './components/sidebar'
import Chat from './components/chat'

import style from './app.module.scss'

export default class App extends Component {
  constructor() {
    super()
    this.state = { loading: true }
  }

  componentDidMount() {
    const roomsRef = firebase.database().ref('rooms')

    firebase.auth().onAuthStateChanged(user => {
      this.setState(() => ({
        userID: user?.uid,
        userName: user?.displayName,
        userMail: user?.email,
        userImage: user?.photoURL,
      }))

      if (user) {
        this.setState(() => ({ loading: true }))
        roomsRef.on('value', snapshot => {
          const rooms = snapshot.val()
          console.log(rooms)
          this.setState(() => ({ loading: false, rooms }))
        })
      } else {
        this.setState(() => ({ loading: false }))
      }
    })
  }

  logout() {
    firebase
      .auth()
      .signOut()
      // .then(() => {})
      .catch(error => {})
  }

  notLoading() {
    const { userID, userName, userImage, rooms } = this.state

    return !userID ? (
      <Login firebase={firebase} />
    ) : (
      <Router>
        <div className={style.container}>
          <Sidebar
            user={{ userID, userName, userImage }}
            logoutFunc={this.logout}
            className={style.sidebar}
            rooms={rooms}
          />

          <Switch>
            <Route
              exact
              path='/room/:roomName'
              render={props => (
                <Chat
                  roomName={props.match.params.roomName}
                  rooms={rooms}
                  className={style.chat}
                />
                // {...props}
              )}
            />
            <Route exact path='/'>
              <Redirect to='/room/general' />
            </Route>
          </Switch>
        </div>
      </Router>
    )
  }

  render() {
    const { loading } = this.state
    return loading ? <h1>Loading...</h1> : this.notLoading()
  }
}
