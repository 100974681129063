import styles from './login.module.scss'
import SignInButton from './signInButton'
const icons = require('simple-icons')

export default function Login({ firebase }) {
  const loginWithProvider = provider => {
    firebase
      .auth()
      .signInWithPopup(provider)
      .catch(error => {
        let { errorCode, errorMessage } = error
        console.log(error)
        console.error('Login failed ' + errorCode + ': ' + errorMessage)
      })
  }

  // TODO: Handle error auth/account-exists-with-different-credential
  // https://firebase.google.com/docs/auth/web/github-auth#expandable-1

  const googleLogin = () => {
    let provider = new firebase.auth.GoogleAuthProvider()
    loginWithProvider(provider)
  }

  const twitterLogin = () => {
    let provider = new firebase.auth.TwitterProvider()
    loginWithProvider(provider)
  }

  const githubLogin = () => {
    let provider = new firebase.auth.GithubAuthProvider()
    loginWithProvider(provider)
  }

  return (
    <div className={styles.container}>
      <div className={styles.center}>
        <h1>Login to Slack-Clone</h1>
        <div>
          <SignInButton
            buttonClicked={googleLogin}
            icon={icons.Google}
            title='Sign in With Google'
          />

          <SignInButton
            buttonClicked={githubLogin}
            icon={icons.GitHub}
            title='Sign in With Github'
          />
        </div>
      </div>
    </div>
  )
}
